<template>
<div>
    <div class="h-handle-bg">
        <div class="h-handle-button">
            <p class="h-return">
                <el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
            </p>
            <div class="h-b">
                <el-button type="primary" size="small" @click="add" v-right-code="'Customervendor:Edit'">新增</el-button>
            </div>
            <div class="h-b">
                <el-button type="text" size="small" @click="save" v-right-code="'Customervendor:Edit'">保存</el-button>
            </div>
        </div>
    </div>

    <div class="form-list">
        <el-form :model="dataSource" ref="_sysConfigForm" :rules="baseCheckRule">
            <el-collapse v-model.trim="formItems">
                <el-collapse-item title="基本信息" name="formItem1">
                    <el-form-item>
                        <el-col :span="3" class="form-title"><span style="color: red">*</span>客商类型：</el-col>
                        <el-col :span="9">
                            <el-form-item prop="VendorTypes">
                                <wl-select v-model="dataSource.VendorTypes" datType='Int' dicTypeCode="VendorTypeCode" :multiple="true" @change="onChangeVendorTypes">
                                </wl-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title"> 客商编码：</el-col>
                        <el-col :span="9">
                            <el-form-item prop="VendorCode">
                                <el-input v-model="dataSource.VendorCode" placeholder="客商编码"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="3" class="form-title"><span style="color: red">*</span> 客商名称：</el-col>
                        <el-col :span="9">
                            <el-form-item prop="VendorName">
                                <el-input v-model="dataSource.VendorName" placeholder="客商名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">客商简称：</el-col>
                        <el-col :span="9">
                            <el-form-item prop="VendorShortName">
                                <el-input v-model="dataSource.VendorShortName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="3" class="form-title">注册地址：</el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <Distpicker :placeholders="dataSource"></Distpicker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item prop="Address">
                                <el-input v-model="dataSource.Address" placeholder="详细地址"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="3" class="form-title">状态：</el-col>
                    <el-col :span="9">
                        <el-form-item prop="ActiveStatus">
                            <DictionarySelect v-model="dataSource.ActiveStatus" datType='Int'
										dicTypeCode="ActiveStatusCode">
									</DictionarySelect>
                        </el-form-item>
                    </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="3" class="form-title">销售经理：</el-col>
                        <el-col :span="9">
                            <el-form-item prop="Manager">
                                <el-input v-model="dataSource.Manager"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">业务员：</el-col>
                        <el-col :span="9">
                            <el-form-item prop="Salesman">
                                <el-input v-model="dataSource.Salesman"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <template v-if="dataSource.VendorTypes && dataSource.VendorTypes.includes('300')">
                            <el-col :span="3" class="form-title">关联货主：</el-col>
                            <el-col :span="9">
                                <LookPopup v-model="dataSource.CustomerName"
                                    dataTitle="货主选择"
                                    dataUrl='omsapi/customerinfo/getlist'
                                    syncField='CustomerName'
                                    @syncFieldEvent="syncCustomerEvent"
                                ></LookPopup>
                            </el-col>
                        </template>
                        <el-col :span="3" class="form-title">助记码：</el-col>
                        <el-col :span="9">
                            <el-form-item prop="MnemonicCode">
                                <el-input v-model="dataSource.MnemonicCode"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                </el-collapse-item>
            </el-collapse>
            
            <el-tabs class="done" type="border-card" v-model="activeName1">
                <el-tab-pane label="联系人" name="tabproductinfo">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="addContactInfo">新增</el-button>
                        </div>
                    </div>
                    <div class="h-table-list">
                        <div class="h-scroll">
                            <div class="table-s">
                                <el-table :data="dataSource.ContactInfoList" border highlight-current-row>
                                    <el-table-column width="55" label="序号">
										<template slot-scope="scope">
											<span>{{scope.$index+1}}</span>
										</template>
									</el-table-column>
                                    <el-table-column prop="ContactName" label="联系人">
                                        <template slot-scope="scope">
                                            <el-col :span="23">
                                                <el-input v-model="scope.row.ContactName" size="mini" placeholder="联系人" />
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ContactPhone" label="联系电话">
                                        <template slot-scope="scope">
                                            <el-col :span="23">
                                                <el-input v-model="scope.row.ContactPhone" size="mini" placeholder="联系电话" />
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="Email" label="电子邮箱">
                                        <template slot-scope="scope">
                                            <el-col :span="23">
                                                <el-input v-model="scope.row.Email" size="mini" placeholder="电子邮箱" />
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" label="操作" width="120">
                                        <template slot-scope="scope">
                                            <el-button @click="dataSource.ContactInfoList.splice(scope.$index, 1)" size="small" type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
					</div>
                </el-tab-pane>
            </el-tabs>
            <el-tabs class="done" type="border-card" v-model="activeName2">
                <el-tab-pane label="装卸货地址" name="tabproductinfo">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="addAddressInfo">新增</el-button>
                        </div>
                    </div>
                    <div class="h-table-list menulist">
                        <div class="h-scroll">
                            <div class="table-s">
                                <el-table :data="dataSource.AddressInfoList" border highlight-current-row>
                                    <el-table-column width="55" label="序号">
										<template slot-scope="scope">
											<span>{{scope.$index+1}}</span>
										</template>
									</el-table-column>
                                    <el-table-column prop="Name" label="名称">
                                        <template slot-scope="scope">
                                            <el-col :span="23">
                                                <el-input v-model="scope.row.Name" size="mini" placeholder="名称" />
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="addressType" label="地址类型">
                                        <template slot-scope="scope">
                                            <el-col :span="23">
                                                <!-- <el-select v-model="dataSource.AddressType" collapse-tags placeholder="请选择" :maxlength="200" :disabled="isDisabled">
                                                    <el-option v-for="item in scope.row.AddressTypeList" :key="item.Key" :label="item.Value" :value="item.Key">
                                                    </el-option>
                                                </el-select> -->
                                                <DictionarySelect v-model="scope.row.AddressType" datType='Int' dicTypeCode="PlatformAddressTypeCode">
                                                </DictionarySelect>
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="MemberProductCode" label="地址" width="400">
                                        <template slot-scope="scope">
                                            <el-col :span="23">
                                                <Distpicker :placeholders="scope.row"></Distpicker>
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="详细地址" width="300">
                                        <template slot-scope="scope">
                                            <el-col :span="23">
                                                <el-form-item :prop="'AddressInfoList.' + scope.$index + '.AddressDetail'" :rules="baseCheckRule.AddressDetail">
                                                    <el-input v-model="scope.row.AddressDetail" size="mini" placeholder="详细地址" />
                                                </el-form-item>
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="经纬度" width="260">
                                        <template slot-scope="scope">
                                            <el-col :span="10">
                                                <el-input v-model="scope.row.Longitude" size="mini" placeholder="经度" />
                                            </el-col>
                                            <el-col :span="10">
                                                <el-input v-model="scope.row.Latitude" size="mini" placeholder="纬度" />
                                            </el-col>
                                            <el-col :span="4">
                                                <el-button @click="locate(scope.row)">定位</el-button>
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ContactName" label="联系人">
                                        <template slot-scope="scope">
                                            <el-col :span="23">
                                                <el-form-item :prop="'AddressInfoList.' + scope.$index + '.ContactName'" :rules="baseCheckRule.ContactName">
                                                    <el-input v-model="scope.row.ContactName" size="mini" placeholder="联系人" />
                                                </el-form-item>
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ContactPhone" label="联系电话">
                                        <template slot-scope="scope">
                                            <el-col :span="23">
                                                <el-form-item :prop="'AddressInfoList.' + scope.$index + '.ContactName'" :rules="baseCheckRule.ContactPhone">
                                                    <el-input v-model="scope.row.ContactPhone" size="mini" placeholder="联系电话" />
                                                </el-form-item>
                                            </el-col>
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" label="操作" width="120">
                                        <template slot-scope="scope">
                                            <el-button size="small" type="text" @click="dataSource.AddressInfoList.splice(scope.$index, 1)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div> 
                </el-tab-pane>
            </el-tabs>
            <el-tabs class="done" type="border-card" v-model.trim="tabModel">
                <el-tab-pane label="资质信息" name="tab1">
                    <div class="h-table-list">  
                        <div class="h-handle-button">
                            <div class="h-b">
                                <el-button type="primary" size="small" @click="certAdd">新增</el-button>
                            </div>
                        </div>
                        <div class="h-scroll">
                            <div class="table-s">
                                <el-table :data="dataSource.CertList" border style="width: 100%">
                                    <el-table-column width="55" label="序号">
                                        <template slot-scope="scope">
                                            <span>{{scope.$index+1}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="CertFileName" label="文件URL">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row['IsEdit']">{{scope.row['CertFileName']}}</span>
                                            <el-input v-else v-model="scope.row['CertFileName']" suffix-icon="el-icon-upload2" readonly @click.native="uploadClick(scope.$index)"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="CertName" label="资质文件名称">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row['IsEdit']">{{scope.row['CertName']}}</span>
                                            <el-input v-else v-model="scope.row['CertName']"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="CertNo" label="证书编号">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row['IsEdit']">{{scope.row['CertNo']}}</span>
                                            <el-input v-else v-model="scope.row['CertNo']"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="CreatedTime" label="有效期">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row['IsEdit']">{{scope.row['EffectiveDate']}}</span>
                                            <el-date-picker v-else v-model="scope.row['EffectiveDate']" placeholder="选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="CreatedTime" label="是否效期控制">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row['IsEdit']">{{scope.row['EffectiveDateFlag']}}</span>
                                            <DictionarySelect v-else v-model="scope.row['EffectiveDateFlag']" datType='Int' dicTypeCode="YesOrNoCode">
                                            </DictionarySelect>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="EffectiveDateWarnDays" label="过期预警天数">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row['IsEdit']">{{scope.row['EffectiveDateWarnDays']}}</span>
                                            <el-input v-else v-model="scope.row['EffectiveDateWarnDays']">
                                                <template slot="append">天</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作">
                                        <template slot-scope="scope">
                                            <el-button type="text" size="small" style="margin-left:10px;" @click="certDownload(scope.row)">下载
                                            </el-button>
                                            <el-button type="text" size="small" style="margin-left:10px;" @click="dataSource.CertList.splice(scope.$index, 1)">删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                    
                </el-tab-pane>
                <el-tab-pane label="合同信息" name="tab2">
                    <ContractList ref="refcontractlist" :customerId="dataSource.VendorId" :isDisabled="isDisabled"></ContractList>
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <DialogEx :options="uploadConfig.viewConfig" :title="uploadConfig.viewConfig.title" @onOk="onConfirmOk" size="small">
            <el-form ref="upload">
                <el-form-item>
                    <el-col :span="24">
                        <el-upload :action="uploadUrl" drag ref="upload" :show-file-list="false" :auto-upload="true" accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4" :on-success="uploadSuccess" :limit="10" :on-exceed="uploadExceed" :before-upload="uploadBefore" class="el-fileUpload">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">
                                注：支持.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4文件，且不超过10MB</div>
                            <!-- <div slot="tip" class="el-upload-tip">

附件列表(支持.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4文件，且不超过50MB)

</div> -->
                        </el-upload>
                    </el-col>
                </el-form-item>
            </el-form>
        </DialogEx>
    </div>
</div>
</template>

<script>
import sha from '../../../../scripts/sha.js';
export default {
    data() {
        return {
            tabModel: 'tab1',
            isDisabled: false,
            activeName1: 'tabproductinfo',
            activeName2: "tabproductinfo",
            baseCheckRule: {
                CustomerCode: [{
                    required: true,
                    message: '请输入客商编码',
                    trigger: 'blur'
                }],
                CustomerName: [{
                    required: true,
                    message: '请输入客商名称',
                    trigger: 'blur'
                }],
                VendorName: [{
                    required: true,
                    message: '请输入供应商名称',
                    trigger: 'blur'
                }],
                VendorTypes: [{
                    required: true,
                    message: '请选择客商类型',
                    trigger: 'change'
                }],
                ProvinceID: [{
                    required: true,
                    message: '请输入省份',
                    trigger: 'blur'
                }],
                AddressDetail: [{
                    required: true,
                    message: '请输入地址',
                    trigger: 'blur'
                }],
                ContactName: [{
                    required: true,
                    message: '请输入联系人',
                    trigger: 'blur'
                }],
                ContactPhone: [{
                    required: true,
                    message: '请输入联系电话',
                    trigger: 'blur'
                }],
                ValidPicker:true
            },
            formItems: ['formItem1', 'formItem2', 'formItem3'],
            uploadUrl: window.AppConfig.apiCustomerUrl + "omsapi/files/upload?currentType=" + this.$store.state.global
                .currentType + "&currentCCode=" + this.$store.state.global.currentCCode + "&token=" + this.Utils
                .getToken(),
            uploadConfig: {
                fileType: 800,
                isShowImage: false, //查看图片
                showImage: '',
                currentIndex: null,
                currentFile: {},
                viewConfig: {
                    visible: false,
                    size: 'small',
                    title: '文件上传',
                    customClass: 'vendorDialog'
                }
            }
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {},
    },
    mounted() {
        this.Event.$on('clearEditSysConfigForm', () => this.resetForm);
    },
    watch: {
        dataSource: {
            handler(curVal, oldVal) {
                this.isDisabled = this.Utils.isGreaterThanZero(
                    this.dataSource.ConfigId
                )
            },
            deep: true,
        },
    },
    methods: {
        add() {
            this.Event.$emit('onAdd')
        },
        resetForm() {
            !this.isPassValidate && this.$refs['_sysConfigForm'].resetFields() //清空表单
        },
        save() {
            var _this = this
            _this.$refs['_sysConfigForm'].validate((valid) => {
                _this.isPassValidate = valid && _this.checkPicker();
                if (_this.isPassValidate) {
                    _this.$ajax.send(
                        'omsapi/customervendor/save',
                        'post',
                        _this.dataSource,
                        (data) => {
                            _this.$parent.syncDataSource(data.Result);
                            _this.Event.$emit(this.config.moduleEvent.initial, data.Result)
                            _this.Utils.messageBox('保存成功.', 'success')
                        }
                    )
                } else {
                    return false
                }
            })
        },
        checkPicker(){
            var flag = true;
            if(this.dataSource.AddressInfoList && this.dataSource.AddressInfoList.length>0)
            {
                var cityIDs = this.Utils.selectionsToArr(this.dataSource.AddressInfoList, "CityID");
                for(var i=0;i<cityIDs.length;i++){
                    if(!cityIDs[i]){
                        flag = false;
                    }
                }
            }
            if(!flag) this.Utils.messageBox('装卸货地址省市区不能为空.', 'error')
            return flag;
        },
        uploadSuccess(response, file, fileList) {
            if (response.IsSuccess) {
                if (response.Result) {
                    this.uploadConfig.currentFile = response.Result;
                } else {
                    this.$message.error(response.OperationDesc);
                }
            }
        },
        uploadBefore(file) {
            this.uploadConfig.currentFile = null;
            const picType = (file.type === 'image/jpeg') || (file.type === 'image/png') || (file.type ===
                    'video/mp4') ||
                (file.type === 'application/msword') || (file.type === 'application/vnd.ms-excel') ||
                (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
                (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
                (file.type === 'text/plain') || (file.name.endsWith('.xls')) || (file.name.endsWith('.xlsx'));
            const isLt50M = file.size / 1024 / 1024 < 10;
            console.log("校验类型");
            if (!picType) {
                this.$message.error('上传文件只能是.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4格式!');
            }
            console.log("校验大小");
            if (!isLt50M) {
                this.$message.error('上传文件大小不能超过 10MB!');

            }
            return picType && isLt50M;
        },
        uploadExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        uploadShowImage(row) {
            var url = row.AttachmentUrl;
            if (url) {
                this.uploadConfig.showImage = url;
                this.uploadConfig.isShowImage = !this.isShowImage;
            }
        },
        isImage(fileName) {
            //获取最后一个.的位置
            var index = fileName.lastIndexOf(".");
            //获取后缀
            var ext = fileName.substr(index + 1);
            return ['png', 'jpg', 'jpeg'].indexOf(ext.toLowerCase()) !== -1;
        },
        certDownload(row) {
            // var url = row.AttachmentUrl;
            // if (url) {
            // 	this.Utils.exportExt(url);
            // }
            var _this = this;
            var urlFile = "omsapi/files/download?file=" + row.CertFileName + "&url=" + row.CertFileUrl + "&token=" +
                sha.getToken();
            _this.Utils.exportUrl(urlFile);
        },
        certAdd() {
            debugger;
            if(!this.dataSource.CertList){
                this.dataSource.CertList = [];
            }
            var item = {
                CertFileName: '',
                CertFileUrl: '',
                CertName: '',
                CertNo: '',
                EffectiveDate: null,
                EffectiveDateFlag: 100,
                EffectiveDateWarDays: null
            };
            this.dataSource.CertList.push(item);
        },
        certEdit(row) {
            row.IsEdit = !row.IsEdit;
        },
        certRemove(index) {
            this.dataSource.CertList.splice(index, 1);
        },
        onConfirmOk() {
            var currentFile = this.uploadConfig.currentFile;
            if (currentFile != null) {
                this.dataSource.CertList[this.uploadConfig.currentIndex].CertFileUrl = currentFile.Url;
                this.dataSource.CertList[this.uploadConfig.currentIndex].CertFileName = currentFile.FileName;
            }
        },
        uploadClick(index) {
            this.uploadConfig.viewConfig.visible = true;
            this.uploadConfig.currentIndex = index;
        },
        contactEdit(row) {
            row.IsEdit = !row.IsEdit;
        },
        addContactInfo: function () {
            var data = {
                ContactName: null,
                ContactPhone: null,
                Email: null,
                RelationId: this.dataSource.VendorId,
                PlatformUserType: 10, //承运商类型
                IsAdd: true
            };
            this.dataSource.ContactInfoList.push(data);
        },
        removeContactInfo: function (row) {
            this.dataSource.ContactInfoList.remove(row);
        },
        addAddressInfo: function () {
            var list = [];
                list.push({
                    Key: 0,
                    Value: "装货地"
                });
                list.push({
                    Key: 5,
                    Value: "卸货地"

                });
                list.push({
                    Key: 10,
                    Value: "装卸货地"
                });
                var data = {
                    AddressType: null,
                    AddressTypeList: list,
                    ProvinceName: null,
                    CityName: null,
                    CountyName: null,
                    ProvinceID: null,
                    CityID: null,
                    CountyID: null,
                    AddressDetail: null,
                    Longitude: null,
                    Latitude: null,
                    ContactName: null,
                    ContactPhone: null,
                };
                this.dataSource.AddressInfoList.push(data);
        },
        removeAddressInfo: function (row) {
            this.dataSource.AddressInfoList.remove(row);
        },
        locate(row){
            this.Utils.setLocation(row);
        },
        onChangeVendorTypes(val){
            debugger;
            if(!val || !val.includes('300')){
                this.dataSource.CustomerID = null;
                this.dataSource.CustomerCode = null;
                this.dataSource.CustomerName = null;
            }
        },
        syncCustomerEvent:function(row){
            debugger;
            if(row){
                this.dataSource.CustomerID = row.CustomerID;
                this.dataSource.CustomerCode = row.CustomerCode;
            }
        },
    },

    components: {
        "ContactInfoSelector": resolve => {
            require(['../../../business/member/shipprovider/components/contactselector.vue'], resolve)
        },
        "AddressInfoSelector": resolve => {
            require(['../../../business/member/shipprovider/components/addressselector.vue'], resolve)
        },
        "ContractList": resolve => {
            require(['../../../enterprise/selector/customercontractlist.vue'], resolve)
        },

    }
}
</script>

<style>
.el-fileUpload .el-upload {}

.vendorDialog.el-dialog {
    width: 30%;
}

.vendorDialog.el-dialog .el-upload {
    display: block;
}

.vendorDialog.el-dialog .el-upload-dragger {
    width: 100%;
}
</style>
